export enum UnitLoad {
  Container = "Container",
  Colli = "Colli",
  Pallet = "Pallet",
}

export enum DeliveryStatusCode {
  New = "10",
  InGoodsReceiptArea = "15",
  PartlyReceived = "25",
  ReadyForDespatch = "30",
  Received = "40",
  Blocked = "50",
  NotReceived = "70",
}
