
import { defineComponent } from "vue";
import {
  DeliveryProduct,
  DeliverySingle,
} from "@/modules/delivery/api/deliverySave";
import ProductAutocomplete from "@/modules/delivery/components/ProductAutocomplete.vue";
import KSelect from "@/components/crud/fields/KSelect.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import { mapGetters } from "vuex";
import eventBus from "@/application/eventBus";

interface ComponentData {
  values: Partial<DeliverySingle>;
  productToAdd: DeliveryProduct | null;
}

export default defineComponent({
  name: "DeliveryFormProducts",
  components: { KTextField, KSelect, ProductAutocomplete },
  props: {
    value: {
      required: true,
    },
    clientId: {
      type: Number,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      findError: "error/find",
    }),
  },
  data: (): ComponentData => ({
    values: {},
    productToAdd: null,
  }),
  created() {
    this.values = this.value;
  },
  watch: {
    values(values) {
      this.$emit("input", values);
    },
    value(values) {
      this.values = values;
    },
  },
  methods: {
    addProduct() {
      if (!this.values) return;
      if (!this.values.items) {
        this.values.items = [];
      }

      const existingItem = this.values.items.findIndex(
        (item) => item.articleNumber === this.productToAdd?.articleNumber
      );

      if (existingItem > -1) {
        eventBus.$emit("snackbar", {
          text: this.$t("delivery.messages.productAlreadyExist"),
          color: "error",
        });
        return;
      }

      this.values.items.push({
        articleName: this.productToAdd?.name || "",
        articleNumber: this.productToAdd?.articleNumber || "",
        quantity: 1,
        unit: this.productToAdd?.units?.[0].name || "",
        units: this.productToAdd?.units,
      });
      this.productToAdd = null;
    },
    removeProduct(index: number) {
      if (!this.values.items?.length) return;
      this.values.items.splice(index, 1);
    },
  },
});
