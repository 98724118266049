
import KPaginatedAutocomplete from "@/components/crud/fields/KPaginatedAutocomplete.vue";
import Vue, { VueConstructor } from "vue";
import { productAutocomplete } from "@/modules/delivery/api/productAutocomplete";
import { PaginatedRequest } from "@/application/api/getPaginated";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        autocomplete: {
          refresh: () => Promise<unknown>;
        };
      };
    }
  >
).extend({
  name: "ProductAutocomplete",
  components: { KPaginatedAutocomplete },
  props: {
    clientId: {
      type: Number,
      required: true,
    },
    excludeAssembly: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    clientId: {
      handler() {
        this.$refs.autocomplete.refresh();
      },
    },
  },
  methods: {
    productAutocomplete(data: PaginatedRequest) {
      return productAutocomplete(this.clientId, {
        ...data,
        params: {
          ...data.params,
          excludeAssembly: this.excludeAssembly ? 1 : undefined,
        },
      });
    },
  },
});
