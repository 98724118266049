
import { defineComponent } from "vue";
import UnitLoadSelect from "@/modules/delivery/components/UnitLoadSelect.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import { DeliverySingle } from "@/modules/delivery/api/deliverySave";
import { UnitLoad } from "@/modules/delivery/enums";

interface ComponentData {
  values: Partial<DeliverySingle>;
}

export default defineComponent({
  name: "DeliveryFormDetails",
  components: { KTextField, UnitLoadSelect },
  props: {
    value: {
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: (): ComponentData => ({
    values: {},
  }),
  computed: {
    UnitLoad() {
      return UnitLoad;
    },
  },
  created() {
    this.values = this.value;
  },
  watch: {
    values(values) {
      this.$emit("input", values);
    },
    value(values) {
      this.values = values;
    },
  },
});
